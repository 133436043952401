// Textpicteaser


@include media-breakpoint-down(sm) {

    .frame-type-wvier_textpicteaser {
        .img-fluid{
            min-width: 100%;
        }
        .container {
            padding-left: 0;
            padding-right: 0;

            background-color: $secondary;
        }
    }

    .textpic-teaser {
        .textpic-teaser-text {
            padding-left: 72px;
            padding-right: 36px;
        }
    }
}

@include media-breakpoint-up(md) {
    .frame-type-wvier_textpicteaser {
        overflow-x: hidden;
    }
}

.textpic-teaser {

    figure {
        margin: 0
    }

    .textpic-teaser-text {
        padding-top: 2rem;
        padding-bottom: 2.625rem;
    }

    @include media-breakpoint-up(md) {

        padding-bottom: 3.25rem;

        .textpic-teaser-text {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 3.25rem;
            padding-top: 2.625rem;

            .frame-text {
                > *:last-child {
                    margin-bottom: 0;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100vw; //it must be bigger because of left / rigth shift
                background-color: $secondary;
                z-index: -1;
            }
        }

        &.textpic-teaser-left {
            .textpic-teaser-text {
                padding-left: 2.625rem;

                &::after {
                    left: -50%;
                }
            }
        }

        &.textpic-teaser-right {
            .textpic-teaser-text {
                padding-right: 2.625rem;

                &::after {
                    right: -50%;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 5.375rem;

        .textpic-teaser-text {
            top: 5.375rem;
            padding-top: 3.875rem;
            padding-bottom: 3.875rem;
        }

        &.textpic-teaser-left {
            .textpic-teaser-text {
                padding-left: 5.75rem;
            }
        }

        &.textpic-teaser-right {
            .textpic-teaser-text {
                padding-right: 5.75rem;
            }
        }
    }
}
